import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import {
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { ExpandMore } from "@material-ui/icons";

import { useDebouncedEffect } from "@hooks/debounceEffect";
import { useStore } from "@store/store";
import {
  FETCH_JOBS,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
  REVIEW_JOBS,
  REVIEW_JOBS_SUCCESS,
  REVIEW_JOBS_FAILURE,
  RESET_REDIRECTION,
} from "@utils/actionTypes";
import Header from "@components/header";
import SearchIcon from "@assets/images/search.svg";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import API from "@services/axios";
import {
  getPermissions,
  getFilter,
  setFilter,
  removeFilter,
  isRolePermission
} from "@utils/commonFunctions";
import { rowsPerPageVal, dataJobStatus } from "@utils/constant";
import { JobListingStyle } from "./style";
import CreateJob from "./create-job";
import CreateCompletedJob from "./create-completed-jobs";
import Filter from "./filter";
import TableListing from "./table-listing";
import { getCustomFormToDate } from "../../utils/commonFunctions";

function JobListing() {
  const classes = JobListingStyle();
  const dashboardFilter = getFilter("jobDashboardFilter", true);
  const filter = getFilter("jobFilter", true);
  const allocateFilter = parseInt(getFilter("jobAllocate"));
  const cargoVolumeFilter = getFilter("cargoVolume");
  const materilClasses = materialCommonStyles();
  const [state, dispatch] = useStore();
  const [openFilter, setOpenFilter] = useState(false);
  const [allocate, setAllocate] = useState(
    !!allocateFilter ? parseInt(allocateFilter) : ""
  );
  const [dataTable, setData] = useState({});
  const [mainCheck, setMainCheck] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [, setClick] = useState(false);
  const [search, setSearch] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [period, setPeriod] = useState(
    filter ? filter?.period : ""
  );
  const [openStartJob, setOpenStartJob] = useState(false);
  const [showStartJob, setShowStartJob] = useState(false);
  const [startJobPeriod, setStartJobPeriod] = useState(
    filter ? filter?.startJobPeriod : dashboardFilter ? dashboardFilter.period : ""
  );
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: rowsPerPageVal
  });
  const [sort, setSort] = useState({
    order: "",
    orderBy: ""
  });

  const [filterData, setFilterData] = useState({
    jobStatus: filter ? filter.jobStatus : [],
    truckRego: filter ? filter.truckRego : [],
    driver: filter ? filter.driver : [],
    customer: filter
      ? filter?.customer
      : dashboardFilter
      ? dashboardFilter.customer
      : [],
    jobType: filter
      ? filter?.jobType
      : dashboardFilter
      ? dashboardFilter.jobType
      : [],
    city: filter ? filter?.city : dashboardFilter ? dashboardFilter.city : [],
    cargoType: cargoVolumeFilter
      ? Array.isArray(cargoVolumeFilter) ? cargoVolumeFilter : [parseInt(cargoVolumeFilter)]
      : filter
      ? filter.cargoType
      : dashboardFilter
      ? dashboardFilter.cargoType
      : [],
    cto: filter ? filter?.cto : dashboardFilter ? dashboardFilter.cto : [],
    startDate: filter
      ? filter?.startDate
      : "",
    endDate: filter
      ? filter?.endDate
      : "",
    startJobStartDate: filter
      ? filter?.startJobStartDate
      : dashboardFilter
      ? dashboardFilter.startDate
      : "",
    startJobEndDate: filter
      ? filter?.startJobEndDate
      : dashboardFilter
      ? dashboardFilter.endDate
      : "",
  });
  const [customDate, setCustomDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [startJobCustomDate, setStartJobCustomDate] = useState({
    startJobStartDate: null,
    startJobEndDate: null,
  });

  const isPermission =
    getPermissions() && isRolePermission(getPermissions(), "jobReview");
  // API calling to get list of jobs
  const paramsGetJob = useMemo(() => {
    return {
      page: pagination.page + 1,
      size: pagination.rowsPerPage,
      ...(!!debounceSearch ? { search: debounceSearch } : {}),
      ...(!!sort.order ? { order: sort.order } : {}),
      ...(!!sort.orderBy ? { orderBy: sort.orderBy } : {}),

      filter: {
        ...(allocate === 1 ? { durationOver3Hours: 1 } : {}),
        ...(allocate === 2 ? { waitingTimeOver2Hours: 1 } : {}),
        ...(allocate === 3 ? { withoutJobCharge: 1 } : {}),
        ...(allocate === 4 ? { reviewNotCompleted: 1 } : {}),
        ...(allocate === 5 ? { AWBNotTakenFully: 1 } : {}),
        ...(allocate === 6 ? { childAccountNotMapped: 1 } : {}),
        ...(!!filterData.jobStatus.length
          ? { jobStatusId: filterData.jobStatus }
          : {}),
        ...(!!filterData.jobType.length
          ? { jobTypeId: filterData.jobType }
          : {}),
        ...(!!filterData.city.length ? { cityId: filterData.city } : {}),
        ...(!!filterData.truckRego.length
          ? { truckId: filterData.truckRego }
          : {}),
        ...(state?.common?.truckId
          ? { truckId: [state.common.truckId.truckId] }
          : {}),
        ...(!!filterData.driver.length ? { driverId: filterData.driver } : {}),
        ...(!!filterData.customer.length
          ? { customerId: filterData.customer }
          : {}),
        ...(!!filterData.cargoType.length
          ? { cargoTypeId: filterData.cargoType }
          : {}),
        ...(!!filterData.cto.length ? { ctoId: filterData.cto } : {}),
        ...(!!filterData.startDate
          ? { startDate: filterData.startDate }
          : !!customDate.startDate
          ? { startDate: filterData.startDate }
          : {}),
        ...(state?.common?.truckId
          ? { startDate: state?.common?.truckId.startDate }
          : {}),
        ...(!!filterData.endDate
          ? { endDate: filterData.endDate }
          : !!customDate.endDate
          ? { endDate: filterData.endDate }
          : {}),
        ...(state?.common?.truckId
          ? { endDate: state?.common?.truckId.endDate }
          : {}),
        ...(!!filterData.startJobStartDate
          ? { startJobStartDate: filterData.startJobStartDate }
          : !!startJobCustomDate.startJobStartDate
          ? { startJobStartDate: filterData.startJobStartDate }
          : {}),
        ...(!!filterData.startJobEndDate
          ? { startJobEndDate: filterData.startJobEndDate }
          : !!startJobCustomDate.startJobEndDate
          ? { startJobEndDate: filterData.startJobEndDate }
          : {}),
      },
    };
  }, [
    pagination,
    debounceSearch,
    sort,
    allocate,
    filterData,
    customDate,
    startJobCustomDate,
    state?.common?.truckId
  ]);

  let getJobs = useCallback((payload = paramsGetJob) => {
      dispatch({ type: FETCH_JOBS });
      let params = payload;
      API.get("jobs", { params })
        .then((response) => {
          dispatch({
            type: FETCH_JOBS_SUCCESS,
            payload: response.data.data,
          });
          setData(response?.data?.data?.rows);
        })
        .catch((error) => {
          dispatch({ type: FETCH_JOBS_FAILURE, payload: error });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsGetJob]);

  useEffect(() => {
    try {
      if (state?.common?.truckId) {
        setPeriod(7);
        setShow(true);
        setFilterData({
          ...filterData,
          truckRego: [state?.common?.truckId.truckId],
          startDate: state?.common?.truckId.startDate,
          endDate: state?.common?.truckId.endDate,
        });
        setCustomDate({
          ...customDate,
          startDate: state?.common?.truckId.startDate,
          endDate: state?.common?.truckId.endDate,
        });
      }
    } catch (error) {
      console.log("Error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination,
    sort,
    state?.common?.truckId,
    period,
    debounceSearch,
  ]);

  useEffect(() => {
    if (state?.redirection?.jobStatusRedirection) {
      setFilterData({
        ...filterData,
        jobStatus: [
          dataJobStatus.find(
            (item) => item.name === state?.redirection?.jobStatusRedirection
          ).id,
        ],
      });
      const payload = {
        ...paramsGetJob,
        filter: {
          ...paramsGetJob?.filter,
          jobStatusId: [
            dataJobStatus.find(
              (item) => item.name === state?.redirection?.jobStatusRedirection
            ).id
          ]
        }
      };
      getJobs(payload);
    } else {
      getJobs();
    }
    return () =>
      dispatch({
        type: RESET_REDIRECTION,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state?.redirection?.filterRedirection,
  ]);

  useEffect(() => {
    if (state?.common?.truckId) {
      setFilter(
        "jobFilter",
        // {
        //   ...filterData,
        //   period:
        //     getCustomFormToDate(
        //       state?.common?.truckId?.startDate,
        //       state?.common?.truckId?.endDate
        //     ) || 7,
        //   startJobPeriod: "",
        // },
        {
          ...filterData,
          startDate: customDate.startDate,
          endDate: customDate.endDate,
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData,customDate, state?.common?.truckId]);

  useEffect(() => {
    setFilter("jobAllocate", allocateFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocateFilter]);

  // Debounce the search after 1 second
  useDebouncedEffect(
    () => {
      setDebounceSearch(search);
      resetPagination();

      const getParamsGetJob = {
        ...paramsGetJob
      };
      delete getParamsGetJob?.search;
      const payload = {
        ...getParamsGetJob,
        page: 1,
        size: rowsPerPageVal,
        ...(!!search ? { search: search } : {})
      };
      getJobs(payload);
    },
    1000,
    [search]
  );

  const openFilterPopup = () => {
    document.body.classList.toggle("open-filter");
    setOpenFilter(true);
  };

  const closeFilerPopup = () => {
    document.body.classList.remove("open-filter");
    setOpenFilter(false);
  };

  const handleSubmit = () => {
    resetPagination();

    const payload = {
      ...paramsGetJob,
      page: 1,
      size: rowsPerPageVal,
    };
    getJobs(payload);
    setFilter("jobFilter", { ...filterData, period, startJobPeriod }, true);
    closeFilerPopup();
  };

  const handleReset = () => {
    removeFilter("jobFilter");
    removeFilter("jobDashboardFilter");
    removeFilter("jobAllocate");
    removeFilter("cargoVolume");
    document.body.classList.remove("open-filter");
    setShow(false);
    setPeriod("");
    setShowStartJob(false);
    setStartJobPeriod("");
    setFilterData({
      jobStatus: [],
      truckRego: [],
      driver: [],
      customer: [],
      jobType: [],
      city: [],
      cargoType: [],
      cto: [],
      startDate: null,
      endDate: null,
      startJobStartDate: null,
      startJobEndDate: null,
    });
    setCustomDate({
      startDate: null,
      endDate: null,
    });
    setStartJobCustomDate({
      startJobStartDate: null,
      startJobEndDate: null,
    });
    resetPagination();

    const payload = {
      ...paramsGetJob,
      page: 1,
      size: rowsPerPageVal,
      filter: {
        ...(!!paramsGetJob?.filter?.durationOver3Hours ? { durationOver3Hours: paramsGetJob?.filter?.durationOver3Hours} : {}),
        ...(!!paramsGetJob?.filter?.waitingTimeOver2Hours ? { waitingTimeOver2Hours: paramsGetJob?.filter?.waitingTimeOver2Hours} : {}),
        ...(!!paramsGetJob?.filter?.AWBNotTakenFully ? { AWBNotTakenFully: paramsGetJob?.filter?.AWBNotTakenFully} : {}),
        ...(!!paramsGetJob?.filter?.withoutJobCharge ? { withoutJobCharge: paramsGetJob?.filter?.withoutJobCharge} : {}),
        ...(!!paramsGetJob?.filter?.reviewNotCompleted ? { reviewNotCompleted: paramsGetJob?.filter?.reviewNotCompleted} : {}),
        ...(!!paramsGetJob?.filter?.childAccountNotMapped ? { childAccountNotMapped: paramsGetJob?.filter?.childAccountNotMapped} : {})
      }
    };
    getJobs(payload);
  };

  const handleCustomDateSubmit = () => {
    setOpen(false);
    setShow(true);
    setFilterData({
      ...filterData,
      startDate: customDate.startDate,
      endDate: customDate.endDate,
    });
    setPeriod(7);
    resetPagination();

    const payload = {
      ...paramsGetJob,
      page: 1,
      size: rowsPerPageVal,
      filter : {
        ...paramsGetJob.filter,
        startDate: customDate.startDate,
        endDate: customDate.endDate,
      }
    };
    getJobs(payload);
  };

  const handleStartJobCustomDateSubmit = () => {
    setOpenStartJob(false);
    setShowStartJob(true);
    setFilterData({
      ...filterData,
      startJobStartDate: startJobCustomDate.startJobStartDate,
      startJobEndDate: startJobCustomDate.startJobEndDate,
    });
    setStartJobPeriod(7);
    resetPagination();

    const payload = {
      ...paramsGetJob,
      page: 1,
      size: rowsPerPageVal,
      filter : {
        ...paramsGetJob.filter,
        startJobStartDate: startJobCustomDate.startJobStartDate,
        startJobEndDate: startJobCustomDate.startJobEndDate,
      }
    };
    getJobs(payload);
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
    resetPagination();

    const payload = {
      ...paramsGetJob,
      page: 1,
      size: rowsPerPageVal,
      filter : {
        ...paramsGetJob.filter,
        ...(name === "truckRego" ? { truckId: value } : { [`${name}Id`]: value })
      }
    };
    getJobs(payload);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value.trimStart());
  };

  const handleSorting = (event, property) => {
    const isAsc = sort.orderBy === property.sortTitle && sort.order === "asc";
    setSort(prev => (
      {
        ...prev,
        order: isAsc ? "desc" : "asc",
        orderBy: property.sortTitle
      }
    ));
    resetPagination();

    const getParamsGetJob = { ...paramsGetJob };
    delete getParamsGetJob?.order;
    delete getParamsGetJob?.orderBy;
    const payload = {
      ...getParamsGetJob,
      page: 1,
      size: rowsPerPageVal,
      order: isAsc ? "desc" : "asc",
      orderBy: property.sortTitle
    };
    getJobs(payload);
  };

  const handleChangePage = (event, newPage) => {
    setPagination((prev) => (
      {
        ...prev,
        page: newPage
      }
    ));

    const payload = {
      ...paramsGetJob,
      page: newPage + 1
    };
    getJobs(payload);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(prev => (
      {
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0
      }
    ));

    const payload = {
      ...paramsGetJob,
      page: 1,
      size: parseInt(event.target.value, 10),
    };
    getJobs(payload);
  };

  const handleAllocate = (event) => {
    setAllocate(event.target.value);
    setFilter("jobAllocate", event.target.value);
    resetPagination();

    const getParamsGetJob = {
      ...paramsGetJob
    };
    delete getParamsGetJob?.filter?.durationOver3Hours;
    delete getParamsGetJob?.filter?.waitingTimeOver2Hours;
    delete getParamsGetJob?.filter?.withoutJobCharge;
    delete getParamsGetJob?.filter?.reviewNotCompleted;
    delete getParamsGetJob?.filter?.AWBNotTakenFully;
    delete getParamsGetJob?.filter?.childAccountNotMapped;
    const payload = {
      ...getParamsGetJob,
      page: 1,
      size: rowsPerPageVal,
      filter: {
        ...getParamsGetJob?.filter,
        ...(event.target.value === 1 ? { durationOver3Hours: 1 } : {}),
        ...(event.target.value === 2 ? { waitingTimeOver2Hours: 1 } : {}),
        ...(event.target.value === 3 ? { withoutJobCharge: 1 } : {}),
        ...(event.target.value === 4 ? { reviewNotCompleted: 1 } : {}),
        ...(event.target.value === 5 ? { AWBNotTakenFully: 1 } : {}),
        ...(event.target.value === 6 ? { childAccountNotMapped: 1 } : {}),
      }
    };
    getJobs(payload);
  };

  // To handle checkboxes from listing
  const handleChange = (item, e) => {
    let _id = item.id;
    let index = dataTable.findIndex((x) => x.id === _id);
    let data = dataTable;
    if (index > -1) {
      let newState = !item._rowChecked;
      data[index]._rowChecked = newState;
      setData(data);
    }
    if (
      data.filter((res, index) => res._rowChecked === true).length ===
      data.length
    ) {
      setMainCheck(true);
    } else {
      setMainCheck(false);
    }
    let newarray = [];
    dataTable.map((res, index) => {
      if (res._rowChecked === true) {
        newarray.push(res.id);
      }
      return true;
    });
    setSelectedArray(newarray);
  };

  // To handle main checkbox
  const handleMainChangeCheckBox = (e) => {
    let _val = e.target.checked;
    dataTable.forEach((element) => {
      if (element.jobStatuses && element.jobStatuses.name === "Completed") {
        element._rowChecked = _val;
      }
    });
    setData(dataTable);
    setMainCheck(_val);

    let newmainarray = [];
    dataTable.map((res, index) => {
      if (res._rowChecked === true) {
        newmainarray.push(res.id);
      }
      return true;
    });
    setSelectedArray(newmainarray);
  };

  // API calling to review multiple jobs
  const handleClickShowData = () => {
    dispatch({ type: REVIEW_JOBS });
    API.put("jobs/reviewCompleted", { jobIds: selectedArray })
      .then((response) => {
        dispatch({
          type: REVIEW_JOBS_SUCCESS,
          payload: response.data.data,
        });
        setClick(true);
        setSelectedArray([]);
        getJobs();
      })
      .catch((error) => {
        dispatch({ type: REVIEW_JOBS_FAILURE, payload: error });
      });
  };

  const handleStartJobPeriodChange = (event, type) => {
    const { value } = event.target;
    setStartJobPeriod(value);
    setShowStartJob(false);
    if (!openFilter) {
      setFilter(
        "jobFilter",
        {
          ...filterData,
          startJobPeriod: value,
        },
        true
      );
    }
    if (value === 7 || type === true) {
      setOpenStartJob(true);
    } else {
      let startJobStartDate;
      let startJobEndDate;
      const newDate = new Date();
      if (value === "") {
        setFilterData({
          ...filterData,
          startJobStartDate: null,
          startJobEndDate: null,
        });
        setStartJobCustomDate({
          startJobStartDate: null,
          startJobEndDate: null,
        });
      } else if (value === 1) {
        startJobStartDate = moment(newDate).format("YYYY-MM-DD");
        startJobEndDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startJobStartDate,
          startJobEndDate,
        });
      } else if (value === 2) {
        startJobStartDate = moment(newDate)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        startJobEndDate = moment(newDate)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startJobStartDate,
          startJobEndDate,
        });
      } else if (value === 3) {
        startJobStartDate = moment(newDate)
          .subtract(3, "day")
          .format("YYYY-MM-DD");
        startJobEndDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startJobStartDate,
          startJobEndDate,
        });
      } else if (value === 4) {
        startJobStartDate = moment(newDate)
          .subtract(7, "day")
          .format("YYYY-MM-DD");
        startJobEndDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startJobStartDate,
          startJobEndDate,
        });
      } else if (value === 5) {
        startJobStartDate = moment(newDate)
          .subtract(14, "day")
          .format("YYYY-MM-DD");
        startJobEndDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startJobStartDate,
          startJobEndDate,
        });
      } else if (value === 6) {
        startJobStartDate = moment(newDate)
          .subtract(30, "day")
          .format("YYYY-MM-DD");
        startJobEndDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startJobStartDate,
          startJobEndDate,
        });
      }
      resetPagination();

      const getParamsGetJob = {
        ...paramsGetJob
      };
      delete getParamsGetJob?.filter?.startJobStartDate;
      delete getParamsGetJob?.filter?.startJobEndDate;
      const payload = {
        ...getParamsGetJob,
        page: 1,
        size: rowsPerPageVal,
        filter : {
          ...getParamsGetJob.filter,
          ...(!!startJobStartDate ? { startJobStartDate } : {}),
          ...(!!startJobEndDate ? { startJobEndDate } : {}),
        }
      };
      getJobs(payload);
    }
  };

  const handlePeriodChange = (event, type) => {
    const { value } = event.target;
    setPeriod(value);
    setShow(false);
    if (!openFilter) {
      setFilter(
        "jobFilter",
        {
          ...filterData,
          period: value,
        },
        true
      );
    }
    if (value === 7 || type === true) {
      setOpen(true);
    } else {
      let startDate;
      let endDate;
      const newDate = new Date();
      if (value === "") {
        setFilterData({
          ...filterData,
          startDate: null,
          endDate: null,
        });
        setCustomDate({
          startDate: null,
          endDate: null,
        });
      } else if (value === 1) {
        startDate = moment(newDate).format("YYYY-MM-DD");
        endDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startDate,
          endDate
        });
      } else if (value === 2) {
        startDate = moment(newDate).subtract(1, "day").format("YYYY-MM-DD");
        endDate = moment(newDate).subtract(1, "day").format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startDate,
          endDate,
        });
      } else if (value === 3) {
        startDate = moment(newDate).subtract(3, "day").format("YYYY-MM-DD");
        endDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startDate,
          endDate,
        });
      } else if (value === 4) {
        startDate = moment(newDate).subtract(7, "day").format("YYYY-MM-DD");
        endDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startDate,
          endDate,
        });
      } else if (value === 5) {
        startDate = moment(newDate).subtract(14, "day").format("YYYY-MM-DD");
        endDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startDate,
          endDate,
        });
      } else if (value === 6) {
        startDate = moment(newDate).subtract(30, "day").format("YYYY-MM-DD");
        endDate = moment(newDate).format("YYYY-MM-DD");
        setFilterData({
          ...filterData,
          startDate,
          endDate,
        });
      }
      resetPagination();

      const getParamsGetJob = {
        ...paramsGetJob
      };
      delete getParamsGetJob?.filter?.startDate;
      delete getParamsGetJob?.filter?.endDate;
      const payload = {
        ...getParamsGetJob,
        page: 1,
        size: rowsPerPageVal,
        filter : {
          ...getParamsGetJob.filter,
          ...(!!startDate ? { startDate } : {}),
          ...(!!endDate ? { endDate } : {}),
        }
      };
      getJobs(payload);
    }
  };

  const handleClose = () => {
    setPeriod("");
    setOpen(false);
    setCustomDate({
      startDate: null,
      endDate: null,
    });
  };

  const handleStartJobClose = () => {
    setStartJobPeriod("");
    setOpenStartJob(false);
    setStartJobCustomDate({
      startJobStartDate: null,
      startJobEndDate: null,
    });
  };

  const handleCustomChange = (event, type) => {
    if (type === "startDate" || type === "endDate") {
      setCustomDate({
        ...customDate,
        [type]: moment(event).format("YYYY-MM-DD"),
      });
    } else {
      const { name, value } = event.target;
      setCustomDate({
        ...customDate,
        [name]: value,
      });
    }
  };
  const handleStartJobCustomChange = (event, type) => {
    if (type === "startJobStartDate" || type === "startJobEndDate") {
      setStartJobCustomDate({
        ...startJobCustomDate,
        [type]: moment(event).format("YYYY-MM-DD"),
      });
    } else {
      const { name, value } = event.target;
      setStartJobCustomDate({
        ...startJobCustomDate,
        [name]: value,
      });
    }
  };
  const resetPagination = () => {
    setPagination(prev => (
      {
        ...prev,
        page: 0,
        rowsPerPage: rowsPerPageVal
      }
    ));
  };

  return (
    <>
      <Header />
      <div className={classes.JobListingWrapper}>
        <div className="dashboard-page wrapper">
          <div className="inner-page">
            <div className={classes.innerPageTopBlock}>
              <div className="left-block">
                <Typography variant="h1">Jobs</Typography>
              </div>

              <div className="right-block">
                <div className="right-block-inner">
                  <div className="search-wrapper">
                    <div className="form-gourp">
                      <TextField
                        id="search-request"
                        placeholder="Search by ID/AWB/Cons./ULD/Info"
                        variant="outlined"
                        type="search"
                        InputProps={{
                          endAdornment: <img src={SearchIcon} alt="Search" />,
                        }}
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="dropdown_wrapper">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        value={allocate}
                        onChange={handleAllocate}
                        displayEmpty
                        className={materilClasses.customSelect}
                        MenuProps={{
                          classes: { paper: materilClasses.customSelect },
                        }}
                        IconComponent={() => <ExpandMore />}
                      >
                        <MenuItem value={""}>Choose</MenuItem>
                        <MenuItem value={1}>Duration Over 3 Hours</MenuItem>
                        <MenuItem value={2}>
                          Jobs Waiting Time Over 2 Hours
                        </MenuItem>
                        <MenuItem value={3}>
                          Jobs Without Any Job Charge
                        </MenuItem>
                        <MenuItem value={4}>
                          Jobs With Review Not Completed
                        </MenuItem>
                        <MenuItem value={5}>
                          Jobs Whose AWB Is Not Taken Fully
                        </MenuItem>
                        <MenuItem value={6}>
                          Jobs With Child Account Not Being Mapped
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="modal-wrapper">
                    {/* https://wymap.atlassian.net/browse/MAPTRAK-1033  */}
                    <CreateJob getJobs={getJobs} />

                    {isPermission && (
                      <div className="btn-wrapper">
                        <Button
                          className="orange-btn primary-btn"
                          color="inherit"
                          disableElevation
                          onClick={handleClickShowData}
                          disabled={selectedArray.length === 0}
                        >
                          Review
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="modal-wrapper">
                    <CreateCompletedJob getJobs={getJobs} />
                  </div>
                  <div className="dropdown_wrapper1">
                    <div className={classes.filter}>
                      <Filter
                        handleFilter={handleFilter}
                        filterData={filterData}
                        openFilterPopup={openFilterPopup}
                        closeFilerPopup={closeFilerPopup}
                        handleSubmit={handleSubmit}
                        handleClose={handleClose}
                        handlePeriodChange={handlePeriodChange}
                        handleCustomChange={handleCustomChange}
                        handleCustomDateSubmit={handleCustomDateSubmit}
                        period={period}
                        open={open}
                        show={show}
                        customDate={customDate}
                        startJobPeriod={startJobPeriod}
                        openStartJob={openStartJob}
                        showStartJob={showStartJob}
                        startJobCustomDate={startJobCustomDate}
                        handleStartJobPeriodChange={handleStartJobPeriodChange}
                        handleStartJobCustomChange={handleStartJobCustomChange}
                        handleStartJobCustomDateSubmit={
                          handleStartJobCustomDateSubmit
                        }
                        handleStartJobClose={handleStartJobClose}
                        handleReset={handleReset}
                        openFilter={openFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TableListing
              handleChange={handleChange}
              handleMainChange={handleMainChangeCheckBox}
              _maincheck={mainCheck}
              page={pagination.page}
              handleChangePage={handleChangePage}
              rowsPerPage={pagination.rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSorting={(e, property) => handleSorting(e, property)}
              orderBy={sort.orderBy}
              order={sort.order}
              getJobs={getJobs}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default JobListing;
