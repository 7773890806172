import {
  FETCH_GEOFENCE,
  FETCH_GEOFENCE_SUCCESS,
  FETCH_GEOFENCE_FAILURE,
  ADD_GEOFENCE,
  ADD_GEOFENCE_SUCCESS,
  ADD_GEOFENCE_FAILURE,
  DELETE_GEOFENCE,
  DELETE_GEOFENCE_SUCCESS,
  DELETE_GEOFENCE_FAILURE,
  FETCH_GEOFENCE_LIST,
  FETCH_GEOFENCE_LIST_SUCCESS,
  FETCH_GEOFENCE_LIST_FAILURE,
  FETCH_GEOFENCE_GROUP_LIST,
  FETCH_GEOFENCE_GROUP_LIST_SUCCESS,
  FETCH_GEOFENCE_GROUP_LIST_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loading: false,
  geofenceData: null,
  loadingAddGeofence: false,
  addGeofenceData: null,
  loadingDeleteGeofence: false,
  deleteGeofenceData: null,
  loadingGeofenceList: false,
  geofenceListData: null,
  loadingGeofenceGroupList: false,
  geofenceGroupListData: null,
};

const geofencesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GEOFENCE:
      return { ...state, loading: true };
    case FETCH_GEOFENCE_SUCCESS:
      return { ...state, geofenceData: action.payload, loading: false };
    case FETCH_GEOFENCE_FAILURE:
      return { ...state, geofenceData: action.payload, loading: false };

    case ADD_GEOFENCE:
      return { ...state, loadingAddGeofence: true };
    case ADD_GEOFENCE_SUCCESS:
      return { ...state, addGeofenceData: action.payload, loadingAddGeofence: false };
    case ADD_GEOFENCE_FAILURE:
      return { ...state, addGeofenceData: action.payload, loadingAddGeofence: false };

    case DELETE_GEOFENCE:
      return { ...state, loadingDeleteGeofence: true };
    case DELETE_GEOFENCE_SUCCESS:
      return { ...state, deleteGeofenceData: action.payload, loadingDeleteGeofence: false };
    case DELETE_GEOFENCE_FAILURE:
      return { ...state, deleteGeofenceData: action.payload, loadingDeleteGeofence: false };

    case FETCH_GEOFENCE_LIST:
      return { ...state, loadingGeofenceList: true };
    case FETCH_GEOFENCE_LIST_SUCCESS:
      return { ...state, geofenceListData: action.payload, loadingGeofenceList: false };
    case FETCH_GEOFENCE_LIST_FAILURE:
      return { ...state, geofenceListData: action.payload, loadingGeofenceList: false };

    case FETCH_GEOFENCE_GROUP_LIST:
      return { ...state, loadingGeofenceGroupList: true };
    case FETCH_GEOFENCE_GROUP_LIST_SUCCESS:
      return { ...state, geofenceGroupListData: action.payload, loadingGeofenceGroupList: false };
    case FETCH_GEOFENCE_GROUP_LIST_FAILURE:
      return { ...state, geofenceGroupListData: action.payload, loadingGeofenceGroupList: false };

    default:
      return state;
  }
};
export default geofencesReducer;
